<template>
  <div class="test">
    <div class="test-title">
      {{ warningStr }}
    </div>
    <div class="test-answer">
      <div
        class="test-answer__item"
        @click="select(true)"
      >
        <span
          class="circle"
          :class="{select:isSelect}"
        >
          <i class="el-icon-check" />
        </span> 是
      </div>
      <div
        class="test-answer__item"
        @click="select(false)"
      >
        <span
          class="circle"
          :class="{select:isSelect===false}"
        >
          <i class="el-icon-check" />
        </span>否
      </div>
    </div>
  </div>
</template>

<script>
import { waringBehavior } from '@/api';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      warningStr: '',
      isSelect: undefined,
    };
  },
  created() {
    this.waringBehavior();
  },
  methods: {
    select(isSelect) {
      if (this.disabled) return;
      this.isSelect = isSelect;
      // if (isSelect === true) {
      //   this.saveEvalWarning();
      // }
      let tmp = this.warningStr;
      if (!this.isSelect) {
        tmp = '';
      }
      this.$emit('selectWarn', { str: tmp, value: this.isSelect });
    },
    waringBehavior() {
      const params = {
        childrenBirthday: this.$route.query.childrenBirthday
      };
      waringBehavior(params).then((res) => {
        this.warningStr = res?.data || '-';
      });
    },
    saveEvalWarning() {
      let tmp = this.warningStr;
      if (!this.isSelect) {
        tmp = '';
      }
      this.$emit('selectWarn', { str: tmp, value: this.isSelect });
      // const params = {
      //   evalRecordId: Number(this.$route.query.evalRecordId),
      //   warningBehavior: this.warningStr
      // };
      // saveEvalWarning(params).then((res) => {
      //   this.$emit('submit');
      // });
    }
  },
};
</script>

<style lang="less" scoped>
.test{
  .border{
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    overflow: hidden;
  }
  &-type{
    .border;

    display: flex;
    margin-bottom: 16px;
    &__title{
      margin-left: 17px;
    }
    .title{
      font-weight: 500;
      font-size: 16px;
      color: #333;
    }
    .text{
      font-size: 14px;
      color: #888;
    }
  }
  .pic img{
    height: 100%;
  }
  &-index{
    margin-bottom: 16px;
    font-size: 14px;
    color: #BDBDBD;
  }
  &-title{
    .border;

    padding: 15px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
  }
  &-text{
    font-size: 16px;
  }
  &-video{
    height: 218px;
    line-height: 218px;
    margin-bottom: 16px;
  }
  &-answer{
    &__item{
      .border;

      margin-bottom: 16px;
      padding: 8px 16px;
      cursor: pointer;
    }
    .circle{
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      border: 1px solid #BDBDBD;
      border-radius: 100%;
      display: inline-block;
      margin-right: 16px;
      color: white;
      &.select{
        background: #85C6CE;
        border-color: #85C6CE;
      }
    }
  }
}
</style>
